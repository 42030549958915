/*jshint esversion: 6 */
import Vue from 'vue'

function URL() {

    if (process.env.NODE_ENV == 'development')
        return Vue.prototype.$config.API_URL_DEV_MODE;
    return Vue.prototype.$config.API_URL;
}

export default {
    URL
};



/*
if (process.env.NODE_ENV == 'development')
return Vue.prototype.$config.API_URL_DEV_MODE;
//return "http://10.31.1.22:9000";
//return window.location.protocol + "//" + window.location.hostname + ":9000";
return Vue.prototype.$config.API_URL;
//return "https://natter.nalogic.es";
//return window.location.protocol + "//" + window.location.hostname + ":" + window.location.port;
*/